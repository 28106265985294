import { Box } from "@xstyled/styled-components";
import React from "react";

import Container from "../components/layout/Container/container";
import ContainerNarrow from "../components/layout/ContainerNarrow/container-narrow";
import Layout from "../components/layout/Main/main";
import PostsList from "../components/pages/news/PostsList/posts-list";
import SEO from "../components/seo";
import Headline from "../components/typography/Headline/headline";

const News = () => (
    <Layout>
        <SEO title="Views &amp; news" description="Blog description." />
        <Container>
            <Box my={5}>
                <Headline textAlign="center">Views &amp; news</Headline>
            </Box>
            <ContainerNarrow pb={5}>
                <PostsList />
            </ContainerNarrow>
        </Container>
    </Layout>
);

export default News;
