import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { breakpoints } from "@xstyled/styled-components";
import { Link } from "gatsby";
import Img from "gatsby-image";
import React from "react";

const S = {};

S.icon = styled(FontAwesomeIcon)`
    margin-right: 2;
    transition: transform 0.25s ease-in-out;
`;

/* eslint-disable-next-line no-unused-vars*/
S.container = styled(({ expanded, ...rest }) => <Link {...rest} />)`
    box-shadow: regular;
    display: flex;
    text-decoration: none;
    color: dark;
    margin-bottom: 3;
    border-radius: 8;
    overflow: hidden;
    flex-direction: ${({ expanded }) => (expanded ? "column" : "row")};
    transition: 0.3s all ease-in-out;

    &:hover {
        box-shadow: regularHover;

        ${S.icon} {
            transform: translateX(3px);
        }
    }
`;

S.article = styled.article`
    padding: 3;
    position: relative;
    max-width: ${({ expanded }) => (expanded ? "auto" : "calc(100% - 100px)")};
    min-width: ${({ expanded }) => (expanded ? "auto" : "calc(100% - 100px)")};
`;

S.cover = styled(Img)`
    max-width: ${({ expanded }) => (expanded ? "100%" : "100px")};
    min-width: ${({ expanded }) => (expanded ? "100%" : "100px")};

    ${({ expanded }) =>
        expanded
            ? breakpoints({
                  xs: `height: 300px;`,
                  sm: `height: 400px;`,
                  md: `height: 400px;`,
              })
            : "100px"}
`;

S.date = styled.time`
    margin-bottom: 3;
    font-weight: bold;
`;

S.excerpt = styled.p`
    font-size: 3;
    font-weight: normal;
    margin-bottom: 3;
`;

S.readMore = styled.p`
    text-align: right;
    margin: ${({ expanded }) => (expanded ? "0" : "-24px 0 0 0")};
    font-weight: bold;
`;

export default S;
