import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import React from "react";

import Headline from "../../../typography/Headline/headline";
import S from "./post-item.style";

const PostItem = ({ path, title, date, excerpt, featuredImage, expanded }) => {
    const dateInstance = new Date(date);
    const dateTime = `${dateInstance.getFullYear()}/${dateInstance.getUTCMonth()}/${dateInstance.getUTCDate()} ${dateInstance.getUTCHours()}:${dateInstance.getUTCMinutes()}`;

    return (
        <S.container to={path} expanded={expanded}>
            <S.cover
                expanded={expanded}
                objectFit="cover"
                fluid={featuredImage.childImageSharp.fluid}
            />
            <S.article expanded={expanded}>
                <Headline as="h3" variant="h3">
                    {title}
                </Headline>
                <S.date datetime={dateTime}>
                    {dateInstance.toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                    })}
                </S.date>
                {expanded && <S.excerpt>{excerpt}</S.excerpt>}
                <S.readMore expanded={expanded}>
                    <S.icon icon={faAngleRight} />
                    Read more
                </S.readMore>
            </S.article>
        </S.container>
    );
};

PostItem.propTypes = {
    path: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    excerpt: PropTypes.string,
    featuredImage: PropTypes.object,
    expanded: PropTypes.bool,
};

export default PostItem;
