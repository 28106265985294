import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import PostItem from "../PostItem/post-item";

const PostsList = () => {
    const data = useStaticQuery(graphql`
        query PostsListQuery {
            allMarkdownRemark(
                filter: {
                    frontmatter: {
                        type: { eq: "blog" }
                        published: { eq: true }
                    }
                }
                sort: { order: DESC, fields: [frontmatter___date] }
            ) {
                edges {
                    node {
                        excerpt(pruneLength: 125)
                        id
                        frontmatter {
                            title
                            date
                            path
                            featuredImage {
                                childImageSharp {
                                    fluid(maxWidth: 540) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    return (
        <>
            {data &&
                data.allMarkdownRemark.edges.map(({ node }, index) => (
                    <PostItem
                        key={node.frontmatter.path}
                        path={node.frontmatter.path}
                        title={node.frontmatter.title}
                        date={node.frontmatter.date}
                        excerpt={node.excerpt}
                        featuredImage={node.frontmatter.featuredImage}
                        expanded={index === 0}
                    />
                ))}
        </>
    );
};

export default PostsList;
